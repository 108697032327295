import { Navigation } from 'swiper';
import { computed, ref } from 'vue';

export function useSwiperOptions(customModules = [], customBreakpoints = {}) {
    const swiperRef = ref();

    const onSwiper = (swiper) => {
        swiperRef.value = swiper;
    };

    const onSlideChange = () => {
        const swiper = swiperRef.value;

        if (!swiper) {
            return;
        }

        const element = swiper.$el[0];

        if (swiper.isEnd) {
            element.classList.add('is-last-slide');
        } else {
            element.classList.remove('is-last-slide');
        }

        if (!swiper.isBeginning) {
            element.classList.add('is-not-first-slide');
        } else {
            element.classList.remove('is-not-first-slide');
        }
    };

    const modules = computed(() => {
        if (!customModules.length) {
            return [Navigation];
        }

        return customModules;
    });

    const breakpoints = computed(() => Object.assign(customBreakpoints));

    return {
        onSwiper,
        onSlideChange,
        modules,
        breakpoints,
        swiperRef,
    };
}
